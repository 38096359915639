<template>
    <div>

        <div class="right-menu shipping-right">
        
            <subHeader pageName="COUPONS" />
            
            <tutorialsAction />

            <div class="db_top">
                <div class="db-container">

                    <moduleStats/>

                    <!-- search block starts here -->
                    <div class="formOptionsBox">
                        <div class="searchOptionsWrap">
                            <div class="searchBlock" id="coupons-search">
                                <form @submit.prevent="">
                                    <div class="searchIconWrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.491 17.49">
                                            <path id="Path_10" data-name="Path 10" d="M16,17.49h0L11,12.5v-.79l-.27-.28a6.512,6.512,0,1,1,.7-.7l.28.27h.79l4.99,5L16,17.489ZM6.5,2A4.5,4.5,0,1,0,11,6.5,4.505,4.505,0,0,0,6.5,2Z" fill="#848484"/>
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        name="search"
                                        id="search"
                                        ref="searchInput"
                                        placeholder="Search"
                                        v-model="searchedItem"
                                        @input="searchCoupon()"
                                        :disabled="tourIsActive"
                                    />
                                </form>
                            </div>
                            <div class="filterShowTabsRow" v-if="searchedItem.length > 0">
                                <div class="filterShowTabsListSearchedQuery">
                                    <p>Search results for "{{ searchedItem.length > 20 ? searchedItem.substr(0, 20) + "..." : searchedItem }}".</p>
                                </div>
                                <div class="filterShowTabsResetBtn">
                                    <button @click.prevent="clearSearch">Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- search block ends here -->


                    <!-- data table starts here -->
                    <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
                        <div class="tableScrollWrap" ref="couponsTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox"
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == couponsList.length"
                                                        :disabled="tourIsActive || this.previewForm.id != ''"
                                                    >
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Coupon Code">Coupon Code</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Valid From">Valid From</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Expiry Date">Expiry Date</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Max Per User Usage">Max Per User Usage</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Max Coupon Usage">Max Coupon Usage</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="couponsLoading">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>

                                    <tr v-if="couponsList && couponsList.length == 0">
                                        <td colspan="7" style="text-align:center"><p class="mb-0">No Coupons Available</p></td>
                                    </tr>
                                    
                                    <tr v-for="(coupon, index) in couponsList" :key="index">
                                        
                                        <!-- checkbox block starts here -->
                                        <td @click="previewCoupon(coupon,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <label class="ctmTableCheckContainer" data-open="false" @click.prevent="coupon.id != -1 ? checkedItemHandle(coupon.id) : ''">
                                                    <input 
                                                        type="checkbox" 
                                                        v-model="checkedItems" 
                                                        :value="coupon.id" 
                                                        :disabled="tourIsActive || coupon.id == -1" 
                                                        data-open="false"
                                                    >
                                                    <span class="checkmark" data-open="false"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <!-- checkbox block ends here -->

                                        <!-- coupon code block starts here -->
                                        <td class="mainTableTitle" @click="previewCoupon(coupon,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="coupon-code-field-3">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a coupon code" 
                                                    v-model="coupon.coupon_code" 
                                                    @focus="couponSelection(coupon)" 
                                                    @blur="handleOTGUpdate(coupon,index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- coupon code block ends here -->

                                        <!-- valid from block starts here -->
                                        <td style="position: relative;" @click.prevent="previewCoupon(coupon,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                {{ coupon.valid_from ? coupon.valid_from : '--' }}
                                            </div>
                                        </td>
                                        <!-- valid from block ends here -->

                                        <!-- expiry data block starts here -->
                                        <td style="position: relative;" @click.prevent="previewCoupon(coupon,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                {{ coupon.valid_to ? coupon.valid_to : '--' }}
                                            </div>
                                        </td>
                                        <!-- expiry date block ends here -->

                                        <!-- max per user usage block starts here -->
                                        <td style="position: relative;" @click.prevent="previewCoupon(coupon,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                {{ coupon.max_per_user_usage ? coupon.max_per_user_usage : "--" }}
                                            </div>
                                        </td>
                                        <!-- max per user usage block ends here -->

                                        <!-- max coupon usage block starts here -->
                                        <td style="position: relative;" @click.prevent="previewCoupon(coupon,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                {{ coupon.max_coupon_usage ? coupon.max_coupon_usage : "--" }}
                                            </div>
                                        </td>
                                        <!-- max coupon usage block ends here -->

                                        <!-- action block starts here -->
                                        <td @click="previewCoupon(coupon,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" v-if="isDeletingItem(coupon.id) && deleteLoader" data-open="true">
                                                <loaderBtn />
                                            </div>
                                            <div class="ctmDataTableActionBtn" data-open="true" v-else>
                                                <button class="delFunction" @click.prevent="deleteCouponHandle(coupon,index)" :disabled="tourIsActive" data-open="false">
                                                    <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- action block ends here -->

                                    </tr>

                                    <!-- load more block starts here -->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- load more block ends here -->

                                </tbody>
                            </table>
                        </div>

                        <!-- add/update coupon form starts here -->
                        <div class="productSlideBox" ref="viewInputForm" :class="previewForm.id ? 'activeRow': ''" >
                            <div class="tableActionBtns" id="coupon-details-preview">
                                <div class="productTableSideBar">
                                    <div class="row">
                                        <div class="col-md-8" id="coupon-code-field-2">
                                            <div class="viewOrderTitle">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a coupon code" 
                                                    v-model="previewForm.coupon_code"
                                                    :disabled="tourIsActive"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="orderViewNav">
                                                <ul>
                                                    <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                                                        <li class="sm-extand-hide">
                                                            <button @click.prevent="expand" :disabled="tourIsActive" id="expand-form">
                                                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                                                        <path d="m16.5 5.5v-4.978l-5.5.014"/>
                                                                        <path d="m16.5.522-6 5.907"/>
                                                                        <path d="m11 16.521 5.5.002-.013-5.5"/>
                                                                        <path d="m16.5 16.429-6-5.907"/>
                                                                        <path d="m.5 5.5v-5h5.5"/>
                                                                        <path d="m6.5 6.429-6-5.907"/>
                                                                        <path d="m6 16.516-5.5.007v-5.023"/>
                                                                        <path d="m6.5 10.5-6 6"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                    <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                                                        <li>
                                                            <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                                                    <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                                                        <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                        <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                                                            <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                            <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sectionTabsLink">
                                    <ul onclick="window.myFunction(event)">
                                        <li v-if="!tourIsActive">
                                            <a class="active" id="coupon-detail-navbtn" href="#couponDetails">Coupon Info</a>
                                        </li>
                                    </ul>
                                    <div class="updateBtnBox" v-if="couponIsSaving">
                                        <loaderBtn/>
                                    </div>
                                    <div class="updateBtnBox" id="save-coupon-btn" v-else>
                                        <button
                                            form="coupon-information" 
                                            button="submit" 
                                            :disabled="tourIsActive"
                                        >
                                            {{ previewForm.id == -1 ? "Save" : "Update" }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <ValidationObserver ref="couponFormObserver">
                                <div class="productTableInfoBlock">
                                    <div class="productTableInfoWhiteBox" id="couponDetails">
                                        <form id="coupon-information" @submit.prevent="validate()">
                                            <div class="tableFromFieldBox">
                                                <label for="coupon-code-field-1">Coupon Code<span>*</span></label>
                                                <ValidationProvider
                                                    name="coupon code" 
                                                    rules="required|max:250" 
                                                    v-slot="{ errors }"
                                                >
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            id="coupon-code-field-1" 
                                                            name="coupon-code-field-1" 
                                                            class="form-control" 
                                                            placeholder="Coupon code" 
                                                            v-model="previewForm.coupon_code"
                                                            :disabled="tourIsActive"
                                                        />
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="valid-from-field">Valid From<span>*</span></label>
                                                <ValidationProvider 
                                                    name="valid from" 
                                                    rules="required" 
                                                    v-slot="{ errors }"
                                                >
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="date" 
                                                            id="valid-from-field"
                                                            name="valid-from-field" 
                                                            class="form-control" 
                                                            v-model="previewForm.valid_from" 
                                                            @input="checkDate()"
                                                            :max="minDate"
                                                            :disabled="tourIsActive"
                                                        />
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="valid-to-field">Valid To<span>*</span></label>
                                                <ValidationProvider
                                                    name="valid to" 
                                                    rules="required" 
                                                    v-slot="{ errors }"
                                                >
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="date"  
                                                            id="valid-to-field"
                                                            name="valid-to-field" 
                                                            class="form-control" 
                                                            :min="previewForm.valid_from"
                                                            v-model="previewForm.valid_to" 
                                                            @input="checkDate()"
                                                            :disabled="tourIsActive"
                                                        />
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="max-per-user-usage">Discount Type<span>*</span></label>
                                                <ValidationProvider
                                                    name="discount type" 
                                                    rules="required" 
                                                    v-slot="{ errors }"
                                                >
                                                    <div class="vueCtmSelectBlock">
                                                        <select 
                                                            class="form-select" 
                                                            aria-label="Default select example" 
                                                            v-model="previewForm.discount_type"
                                                            id="discount-type-field"
                                                            :disabled="tourIsActive"
                                                        >
                                                            <option selected value="Percentage">Percentage</option>
                                                            <option value="Flat">Flat</option>
                                                        </select>
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="discount-value-field">Discount Value<span>*</span></label>
                                                <ValidationProvider 
                                                    name="discount value" 
                                                    :rules="previewForm.discount_type == 'Percentage' ? 
                                                    { required:true,max_value:99,min_value:1,regex: '^[0-9][0-9]*$' } : 
                                                    { required:true,min_value:1,regex: '^[0-9][0-9]*$' }" 
                                                    v-slot="{ errors }"
                                                >
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            id="discount-value-field"
                                                            name="discount-value-field" 
                                                            class="form-control"
                                                            placeholder="Discount value"
                                                            v-model="previewForm.max_discount_value" 
                                                            :disabled="tourIsActive"
                                                        />
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="max-coupon-usage-field">Max Coupon Usage</label>
                                                <ValidationProvider 
                                                    name="max coupon usage" 
                                                    :rules="{max_value:99999999}" 
                                                    v-slot="{ errors }"
                                                >
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            id="max-coupon-usage-field"
                                                            name="max-coupon-usage-field" 
                                                            class="form-control"
                                                            placeholder="Max coupon usage"
                                                            v-model="previewForm.max_coupon_usage" 
                                                            :disabled="tourIsActive"
                                                        />
                                                    </div>
                                                    <span class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="max-per-user-usage-field">Max Per User Usage</label>
                                                <ValidationProvider
                                                    name="max per user usage" 
                                                    :rules="{max_value:previewForm.max_coupon_usage,regex:'^[0-9]+$'}" 
                                                    v-slot="{ errors }"
                                                >
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            id="max-per-user-usage-field"
                                                            name="max-per-user-usage-field" 
                                                            class="form-control" 
                                                            placeholder="Max per user usage usage"
                                                            v-model="previewForm.max_per_user_usage" 
                                                            :disabled="tourIsActive"
                                                        />
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <!-- add/update coupon form ends here -->

                    </div>
                    <!-- data table ends here -->

                    <!--bottom action bar starts here -->
                    <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
                        <div class="col-md-6 model-footer-main1">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != couponsList.length">
                                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                    <p>SELECT ALL</p>
                                </a>
                                <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                    </svg>
                                    <p>CLEAR SELECTION</p>
                                </a>
                            </div>
                            <div class="model-footer-2">{{ checkedItems.length }}</div>
                        </div>
                        <div class="col-md-6 model-footer-main1 product-row-reverse">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 ml-4" @click.prevent="bulkActionHandle('delete')">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    <p>DELETE</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!--bottom action bar ends here -->

                </div>
            </div>
        </div>

        <v-tour name="couponsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import { MessageBox, Message, Loading } from "element-ui";
import subHeader from "@/components/subHeader.vue";
import moduleStats from "@/components/moduleStats.vue";
import { mapGetters } from 'vuex';
import _ from "lodash";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
    metaInfo(){
        return {
            title: 'Marketing | Coupons | YeetCommerce',
        };
    },
    data(){
        return{
            //search start
            searchedItem:'',
            //search end

            //coupons start
            couponsList: [],
            couponsLoading:false,
            previewForm:{
                id:"",
                valid_from: "",
                valid_to: "",
                coupon_code: "",
                max_per_user_usage: "",
                max_coupon_usage: "",
                max_discount_value: "",
                discount_type: "Percentage",
                isOpen: false,
                isTouched:false,
            },
            defaultPreviewForm:{
                id:"",
                valid_from: "",
                valid_to: "",
                coupon_code: "",
                max_per_user_usage: "",
                max_coupon_usage: "",
                max_discount_value: "",
                discount_type: "Percentage",
                isOpen: false,
                isTouched:false,
            },
            tempCoupon:{
                id:"",
                valid_from: "",
                valid_to: "",
                coupon_code: "",
                max_per_user_usage: "",
                max_coupon_usage: "",
                max_discount_value: "",
                discount_type: "Percentage",
                isOpen: false,
                isTouched:false,
            },
            tempPreviewCoupon:{
                id:"",
                valid_from: "",
                valid_to: "",
                coupon_code: "",
                max_per_user_usage: "",
                max_coupon_usage: "",
                max_discount_value: "",
                discount_type: "Percentage",
                isOpen: false,
                isTouched:false,
            },
            couponSaved:false,
            couponIsSaving:false,
            //coupons end

            //checked items start
            checkedItems:[],
            deletingItems:[],
            deleteLoader:false,
            timer: undefined,
            isMasterSearched:false,
            //checked items end

            //tour start
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //search bar step
                {
                    target: '#coupons-search',
                    content: `<strong>Search Coupon</strong><br>It enables you to search specific coupons.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                //form steps
                {
                    target: '#preview-record',
                    content: `<strong>Coupons Table</strong><br>You can preview and edit the added coupons by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'top'
                    }
                },
                {
                    target: '#add-new-record',
                    content: `Add a <strong>Coupon</strong><br>By clicking this button, you can add a new coupon and open a form to input and save the details.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#coupon-details-preview',
                    content: `<strong>Coupon Details Form</strong><br>This form can be used to add details about the coupon and save them.`,
                    params: {
                        highlight: false,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#coupon-code-field-1',
                    content: `<strong>Coupon Code</strong><br>Coupon code can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#valid-from-field',
                    content: `<strong>Valid From Date</strong><br>Coupon valid from date can be entered using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#valid-to-field',
                    content: `<strong>Valid To Date</strong><br>Coupon valid to date can be entered using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#discount-type-field',
                    content: `<strong>Discount Type</strong><br>Discount type can be set to either Flat or Percentage.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#discount-value-field',
                    content: `<strong>Discount Value</strong><br>Discount value can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#max-coupon-usage-field',
                    content: `<strong>Max Coupon Usage</strong><br>Maximum limit of coupon usage can be defined here.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#max-per-user-usage-field',
                    content: `<strong>Max Per User Usage</strong><br>Maximum per user usage of a coupon can be defined here.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#coupon-code-field-3',
                    content: `<strong>Coupon Code</strong><br>Coupon code can be added using this field too.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'right'
                    }
                },
                {
                    target: '#coupon-code-field-2',
                    content: `<strong>Coupon Code</strong><br>Coupon code can be added using this field too.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'right'
                    }
                },
                {
                    target: '#expand-form',
                    content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#closeSide',
                    content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#save-coupon-btn',
                    content: `<strong>Save Coupon Details</strong><br>Added coupon details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
            ],
            tourIsActive:false,
            //tour end
            
        }
    },
    components: {
        subHeader,
        tutorialsAction,
        moduleStats,
        loaderBtn
    },
    computed:{
        ...mapGetters({
            setting: 'settings_module/settings',
            storeCoupons: 'coupons_module/coupons',
            storeCouponsCurrentPage: 'coupons_module/currentPage',
            storeCouponsLastpage: 'coupons_module/lastPage',
            couponSearchKeyword:'coupons_module/keyword',
            isPending: 'coupons_module/isPending',
        }),
        minDate(){

            var dtToday = new Date();

            var month = dtToday.getMonth() + 1;
            var day = dtToday.getDate();
            var year = dtToday.getFullYear();

            if(month < 10){

                month = '0' + month.toString();

            }

            if(day < 10){

                day = '0' + day.toString();

            }

            var maxDate = year + '-' + month + '-' + day;

            return maxDate;

        },
    },
    watch:{
        'storeCoupons':{
            handler:function(value){
                
                this.couponsLoading = false;

                if(this.previewForm.id == -1){
                    
                    this.couponsList = _.cloneDeep(value);

                    this.couponsList.unshift(this.previewForm);

                }else if(this.tempPreviewCoupon.id != ''){
                    
                    this.couponsList = _.cloneDeep(value);

                    this.couponsList[this.previewForm.index] = this.previewForm;

                }else{

                    this.couponsList = _.cloneDeep(value);

                }

                if(this.isMasterSearched){

                    this.previewSearchedCoupon(this.couponsList[0],0);

                    this.isMasterSearched = false;

                }

            },deep:true
        },
        'previewForm':{
            handler:function(value){

                if((value.id == -1) && (value.coupon_code || value.valid_from || 
                value.valid_to || value.discount_type || value.max_discount_value)){

                    value.isTouched = true;

                }else{

                    value.isTouched = false;

                }

            },deep:true
        }
    },
    methods:{
        //infinite scroll start
        handleScrollEvent(event){

            let element = this.$refs.couponsTable;

            let maxScrollTop = element.scrollHeight - element.clientHeight;

            if(element.scrollTop >= maxScrollTop - 100){

                if(this.isPending == false && this.storeCouponsCurrentPage != this.storeCouponsLastpage){

                    this.$store.dispatch('coupons_module/fetchStoreCouponsRequest',{size:11, page: this.storeCouponsCurrentPage + 1, loadData: 'new'});

                }

            }

        },
        //infinite scroll end

        //OTG update start
        couponSelection(listedCoupon){

            this.tempCoupon = _.cloneDeep(listedCoupon);

        },
        async handleOTGUpdate(listedCoupon,index){
        
            if(this.previewForm.id == ''){
                
                if(listedCoupon.id != -1){
                    
                    let isRequired = false;

                    if(listedCoupon.coupon_code.length == 0){

                        this.$notify({
                            type: 'warning',
                            title: 'Alert',
                            message: "The coupon code field is required.",
                        });

                        isRequired = true;

                    }

                    if(listedCoupon.coupon_code.length > 250){

                        this.$notify({
                            type: 'warning',
                            title: 'Alert',
                            message: "The coupon code field may not be greater than 250 characters.",
                        });

                        isRequired = true;

                    }

                    if(isRequired){

                        this.couponsList[index].coupon_code = this.tempCoupon.coupon_code;

                    }
                
                    let isChanged = false

                    if(this.tempCoupon.coupon_code != listedCoupon.coupon_code){

                        isChanged = true;

                    }

                    if(isChanged){
                    
                        MessageBox.confirm(
                            'You have unsaved changes, save and proceed?',
                            'Confirm',
                            {
                                distinguishCancelAndClose: true,
                                confirmButtonText: 'Save',
                                cancelButtonText: 'Discard Changes',
                            }
                        ).then(async () => {

                            let formData = new FormData();

                            formData.append("id", listedCoupon.id);
                            formData.append("coupon_code", listedCoupon.coupon_code);
                            formData.append("max_coupon_usage",listedCoupon.max_coupon_usage ? parseInt(listedCoupon.max_coupon_usage) : null);
                            formData.append("max_per_user_usage",listedCoupon.max_per_user_usage ? parseInt(listedCoupon.max_per_user_usage) : null);
                            formData.append("valid_from", listedCoupon.valid_from);
                            formData.append("valid_to", listedCoupon.valid_to);
                            formData.append("discount_type", listedCoupon.discount_type);
                            formData.append("max_discount_value", listedCoupon.max_discount_value);

                            let loader = Loading.service({
                                text: "The coupon is being updated. Please wait!",
                                fullscreen: true,
                            });

                            try{
                                let res = await this.$axios.post("/updateCoupon", formData);
                                if(res.data.status_code == "1072"){
                                
                                    this.$notify({
                                        type: "success",
                                        title: "Success",
                                        message: res.data.message,
                                    });

                                    this.$store.commit('coupons_module/update_coupons_list',res.data.coupon);

                                    this.tempCoupon = [];

                                }
                            }catch(error){

                                this.couponsList[index].coupon_code = this.tempCoupon.coupon_code;                   

                                this.tempCoupon = [];

                                if(error.response){

                                    if(error.response.data.error.id){

                                        this.$message({
                                            type: "error",
                                            showClose: true,
                                            message: error.response.data.error.id[0],
                                        });

                                    }else if(error.response.data.error.includes('already')){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            dangerouslyUseHTMLString: true,
                                            message: error.response.data.error,
                                        });

                                    }else if(error.response.data.error.includes("doesn't support")){

                                        let subscriptionPath = window.location.origin + "/subscription";

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            dangerouslyUseHTMLString: true,
                                            message: error.response.data.error 
                                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                        });

                                    }else{

                                        this.$message({
                                            type: "error",
                                            showClose: true,
                                            message: error.response.data.message,
                                        });

                                    }

                                }else{

                                    this.$message({
                                        type: "error",
                                        showClose: true,
                                        message: error.message,
                                    });

                                }

                            }finally{

                                loader.close();

                            }

                        }).catch((error) => {

                            this.couponsList[index].coupon_code = this.tempCoupon.coupon_code;                   

                            this.tempCoupon = [];

                            this.$notify({
                                type: "info",
                                title: "Changes Discarded",
                                message: "Changes have been successfully discarded.",
                            });

                        });

                    }

                }
            
            }else{

                if(listedCoupon.id != -1){

                    if(listedCoupon.id != this.previewForm.id){

                        let isRequired = false;

                        if(listedCoupon.coupon_code.length == 0){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The coupon code field is required.",
                            });

                            isRequired = true;

                        }

                        if(listedCoupon.coupon_code.length > 250){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The coupon code field may not be greater than 250 characters.",
                            });

                            isRequired = true;

                        }

                        if(isRequired){

                            this.couponsList[index].coupon_code = this.tempCoupon.coupon_code;

                        }
                    
                        let isChanged = false;

                        if(this.tempCoupon.coupon_code != listedCoupon.coupon_code){

                            isChanged = true;

                        }

                        if(isChanged){
                        
                            MessageBox.confirm(
                                'You have unsaved changes, save and proceed?',
                                'Confirm',
                                {
                                    distinguishCancelAndClose: true,
                                    confirmButtonText: 'Save',
                                    cancelButtonText: 'Discard Changes',
                                }
                            ).then(async () => {

                                let formData = new FormData();

                                formData.append("id", listedCoupon.id);
                                formData.append("coupon_code", listedCoupon.coupon_code);
                                formData.append("max_coupon_usage",listedCoupon.max_coupon_usage ? parseInt(listedCoupon.max_coupon_usage) : null);
                                formData.append("max_per_user_usage",listedCoupon.max_per_user_usage ? parseInt(listedCoupon.max_per_user_usage) : null);
                                formData.append("valid_from", listedCoupon.valid_from);
                                formData.append("valid_to", listedCoupon.valid_to);
                                formData.append("discount_type", listedCoupon.discount_type);
                                formData.append("max_discount_value", listedCoupon.max_discount_value);

                                let loader = Loading.service({
                                    text: "The coupon is being updated. Please wait!",
                                    fullscreen: true,
                                });

                                try{
                                    let res = await this.$axios.post("/updateCoupon", formData);
                                    if(res.data.status_code == "1072"){
                                    
                                        this.$notify({
                                            type: "success",
                                            title: "Success",
                                            message: res.data.message,
                                        });

                                        this.$store.commit('coupons_module/update_coupons_list',res.data.coupon);

                                        this.tempCoupon = [];

                                    }
                                }catch(error){

                                    this.couponsList[index].coupon_code = this.tempCoupon.coupon_code;                

                                    this.tempCoupon = [];

                                    if(error.response){

                                        if(error.response.data.error.id){

                                            this.$message({
                                                type: "error",
                                                showClose: true,
                                                message: error.response.data.error.id[0],
                                            });

                                        }else if(error.response.data.error.includes('already')){

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                dangerouslyUseHTMLString: true,
                                                message: error.response.data.error,
                                            });

                                        }else if(error.response.data.error.includes("doesn't support")){

                                            let subscriptionPath = window.location.origin + "/subscription";

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                dangerouslyUseHTMLString: true,
                                                message: error.response.data.error 
                                                + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                            });

                                        }else{

                                            this.$message({
                                                type: "error",
                                                showClose: true,
                                                message: error.response.data.message,
                                            });

                                        }

                                    }else{

                                        this.$message({
                                            type: "error",
                                            showClose: true,
                                            message: error.message,
                                        });

                                    }

                                }finally{

                                    loader.close();

                                }

                            }).catch(() => {

                                this.couponsList[index].coupon_code = this.tempCoupon.coupon_code;                      

                                this.tempCoupon = [];

                                this.$notify({
                                    type: "info",
                                    title: "Changes Discarded",
                                    message: "Changes have been successfully discarded.",
                                });

                            });

                        }

                    }else{

                    }

                }else{

                }

            }

        },
        //OTG update end

        //preview form open/close & reset start
        previewCoupon(listedCoupon,i,field,e){

            if(field == 'input'){
            
                if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){
                
                    if(listedCoupon.id != -1){
                    
                        if(this.previewForm.id == -1){
                        
                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                    type: "warning",
                                }
                            ).then(()=>{

                                let index = this.couponsList.findIndex((coupon) => coupon.id == -1);

                                if(index != -1){

                                    this.couponsList.splice(index,1); 

                                }

                                if(!this.couponSaved){

                                    let tempIndex = this.couponsList.findIndex(x=>x.id == this.tempPreviewCoupon.id);

                                    if(tempIndex != -1){

                                        this.couponsList[tempIndex] = this.tempPreviewCoupon;

                                    }

                                }

                                let coupon = listedCoupon;

                                this.previewForm = coupon;
                                this.previewForm.index = i;

                                this.checkedItems = [];

                                this.couponIsSaving = false;

                                this.tempPreviewCoupon = _.cloneDeep(coupon);

                            }).catch(() => {})

                        }else{
                            
                            let index = this.couponsList.findIndex((coupon) => coupon.id == -1);

                            if(index != -1){

                                this.couponsList.splice(index,1); 

                            }

                            if(!this.couponSaved && (this.tempPreviewCoupon.id != listedCoupon.id)){
                                
                                let tempIndex = this.couponsList.findIndex(x=>x.id == this.tempPreviewCoupon.id);

                                if(tempIndex != -1){

                                    this.couponsList[tempIndex] = this.tempPreviewCoupon;

                                }

                            }

                            if(this.tempPreviewCoupon.id != '' && this.tempPreviewCoupon.id == listedCoupon.id){
                            
                                let tempIndex = this.couponsList.findIndex(x=>x.id == this.tempPreviewCoupon.id);
                                
                                this.couponsList[tempIndex] = _.cloneDeep(this.storeCoupons[tempIndex]);

                                let coupon = _.cloneDeep(this.couponsList[tempIndex]);

                                this.previewForm = this.couponsList[tempIndex];
                                this.previewForm.index = i;

                                this.checkedItems = [];

                                this.couponIsSaving = false;

                                this.tempPreviewCoupon = _.cloneDeep(coupon);
                                
                            }else{

                                let coupon = listedCoupon;

                                this.previewForm = coupon;
                                this.previewForm.index = i;

                                this.checkedItems = [];

                                this.couponIsSaving = false;

                                this.tempPreviewCoupon = _.cloneDeep(coupon);

                            }

                        }

                    }
                
                }

            }else{

                if(!this.tourIsActive){
                
                    if(listedCoupon.id != -1){
                    
                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                    type: "warning",
                                }
                            ).then(()=>{

                                let index = this.couponsList.findIndex((coupon) => coupon.id == -1);

                                if(index != -1){

                                    this.couponsList.splice(index,1); 

                                }

                                if(!this.couponSaved){

                                    let tempIndex = this.couponsList.findIndex(x=>x.id == this.tempPreviewCoupon.id);

                                    if(tempIndex != -1){

                                        this.couponsList[tempIndex] = this.tempPreviewCoupon;

                                    }

                                }

                                let coupon = listedCoupon;

                                this.previewForm = coupon;
                                this.previewForm.index = i;

                                this.checkedItems = [];

                                this.couponIsSaving = false;

                                this.tempPreviewCoupon = _.cloneDeep(coupon);

                            }).catch(() => {})
                        
                        }else{

                            let index = this.couponsList.findIndex((coupon) => coupon.id == -1);

                            if(index != -1){

                                this.couponsList.splice(index,1); 

                            }

                            if(!this.couponSaved && (this.tempPreviewCoupon.id != listedCoupon.id)){
                                
                                let tempIndex = this.couponsList.findIndex(x=>x.id == this.tempPreviewCoupon.id);

                                if(tempIndex != -1){

                                    this.couponsList[tempIndex] = this.tempPreviewCoupon;

                                }

                            }

                            if(this.tempPreviewCoupon.id != '' && this.tempPreviewCoupon.id == listedCoupon.id){
                            
                                let tempIndex = this.couponsList.findIndex(x=>x.id == this.tempPreviewCoupon.id);
                                
                                this.couponsList[tempIndex] = _.cloneDeep(this.storeCoupons[tempIndex]);

                                let coupon = _.cloneDeep(this.couponsList[tempIndex]);

                                this.previewForm = this.couponsList[tempIndex];
                                this.previewForm.index = i;

                                this.checkedItems = [];

                                this.couponIsSaving = false;

                                this.tempPreviewCoupon = _.cloneDeep(coupon);
                                
                            }else{

                                let coupon = listedCoupon;

                                this.previewForm = coupon;
                                this.previewForm.index = i;

                                this.checkedItems = [];

                                this.couponIsSaving = false;

                                this.tempPreviewCoupon = _.cloneDeep(coupon);

                            }
                            
                        }

                    }
                
                }

            }

        },
        previewSearchedCoupon(listedCoupon,i){

            let index = this.couponsList.findIndex((coupon) => coupon.id == -1);

            if(index != -1){

                this.couponsList.splice(index,1); 

            }

            if(!this.couponSaved){

                let tempIndex = this.couponsList.findIndex(x=>x.id == this.tempPreviewCoupon.id);

                if(tempIndex != -1){

                    this.couponsList[tempIndex] = this.tempPreviewCoupon;

                }

            }

            let coupon = listedCoupon;

            this.previewForm = coupon;
            this.previewForm.index = i;

            this.checkedItems = [];

            this.couponIsSaving = false;
            
            this.tempPreviewCoupon = _.cloneDeep(coupon);

        },
        closePreview(){

            if(this.previewForm.isTouched){

                MessageBox.confirm(
                    "Do you want to close? Entered information will be discarded.",
                    "Warning", 
                    {
                        type: "warning",
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                    }
                ).then(async () => {

                    let index = this.couponsList.findIndex((brand) => brand.id == -1);

                    if(index != -1){

                        this.couponsList.splice(index,1); 

                    }

                    if(!this.couponSaved){

                        let tempIndex = this.couponsList.findIndex(x=>x.id == this.tempPreviewCoupon.id);

                        if(tempIndex != -1){

                            this.couponsList[tempIndex] = this.tempPreviewCoupon;

                        }

                    }

                    this.previewForm = _.cloneDeep(this.defaultPreviewForm);

                    this.$refs.couponFormObserver.reset();

                    this.couponSaved = false;

                    this.tempCoupon = _.cloneDeep(this.defaultPreviewForm);
            
                    this.tempPreviewCoupon = _.cloneDeep(this.defaultPreviewForm);

                }).catch(() => {})

            }else{

                let index = this.couponsList.findIndex((brand) => brand.id == -1);

                if(index != -1){

                    this.couponsList.splice(index,1); 

                }

                if(!this.couponSaved){

                    let tempIndex = this.couponsList.findIndex(x=>x.id == this.tempPreviewCoupon.id);

                    if(tempIndex != -1){

                        this.couponsList[tempIndex] = this.tempPreviewCoupon;

                    }
                    
                }

                this.previewForm = _.cloneDeep(this.defaultPreviewForm);

                this.$refs.couponFormObserver.reset();

                this.couponSaved = false;

                this.tempCoupon = _.cloneDeep(this.defaultPreviewForm);
            
                this.tempPreviewCoupon = _.cloneDeep(this.defaultPreviewForm);

            }

        },
        resetPreview(){
            
            let index = this.couponsList.findIndex((coupon) => coupon.id == -1);

            if(index != -1){

                this.couponsList.splice(index,1); 

            }

            this.previewForm = _.cloneDeep(this.defaultPreviewForm);

            this.couponSaved = false;

            this.tempCoupon = _.cloneDeep(this.defaultPreviewForm);
            
            this.tempPreviewCoupon = _.cloneDeep(this.defaultPreviewForm);

        },
        createCoupon(){

            if(this.couponsLoading){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "Coupons are being retrieved.",
                });

                return
            }

            if(this.couponIsSaving){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "A coupon is currently being processed.",
                });

                return

            }

            let index = this.couponsList.findIndex(x=>x.id == -1);
            
            if(index != -1){

                // this.$notify({
                //   type: "error",
                //   title: "Can't Add New Brand",
                //   message: "you have an unsaved coupon in the list",
                // });

                return
                
            }

            this.closePreview();

            this.previewForm = _.cloneDeep(this.defaultPreviewForm);

            this.previewForm.id = -1;

            let tempCoupon = this.previewForm;

            this.couponsList.unshift(tempCoupon);

            this.checkedItems = [];

            this.$refs.couponFormObserver.reset();

        },
        //preview form open/close & reset end

        //add/update & delete coupon start
        async validate(){

            this.$refs.couponFormObserver.validate().then((success) => {

                if(!success){

                    const errors = Object.entries(this.$refs.couponFormObserver.errors)
                    .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                    this.$refs.couponFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({behavior: "smooth", block: "center",});

                }else{

                    if(this.previewForm.id == -1){

                        this.addCouponHandle();

                    }else{

                        this.updateCouponHandle();

                    }

                }

            });

        },
        async addCouponHandle(){

            let formData = new FormData();

            formData.append('coupon_code',this.previewForm.coupon_code);
            formData.append('valid_from',this.previewForm.valid_from);
            formData.append('valid_to',this.previewForm.valid_to);
            formData.append('discount_type',this.previewForm.discount_type);
            formData.append('max_discount_value',this.previewForm.max_discount_value);

            formData.append("max_coupon_usage",this.previewForm.max_coupon_usage ? parseInt(this.previewForm.max_coupon_usage) : null);
            formData.append("max_per_user_usage",this.previewForm.max_per_user_usage ? parseInt(this.previewForm.max_per_user_usage) : null);

            this.couponIsSaving = true
            try{
                let res = await this.$axios.post("/addCoupon", formData);
                if(res.data.status_code == "1071"){

                    this.$notify({
                        type: "success",
                        title: "Success",
                        message: res.data.message,
                    });

                    this.$store.commit('coupons_module/add_coupons_list',res.data.coupon);

                    this.couponSaved = true;

                    this.resetPreview();

                } 
            }catch(error){

                if(error.response){

                    if(error.response.data.error.includes('already')){

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else if(error.response.data.error.includes("doesn't support")){

                        let subscriptionPath = window.location.origin + "/subscription";

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error 
                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                        });

                    }else{

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.couponIsSaving = false;

            }

        },
        async updateCouponHandle(){

            let formData = new FormData();

            formData.append('id',this.previewForm.id);
            formData.append('coupon_code',this.previewForm.coupon_code);
            formData.append('valid_from',this.previewForm.valid_from);
            formData.append('valid_to',this.previewForm.valid_to);
            formData.append('discount_type',this.previewForm.discount_type);
            formData.append('max_discount_value',this.previewForm.max_discount_value);

            formData.append("max_coupon_usage",this.previewForm.max_coupon_usage ? parseInt(this.previewForm.max_coupon_usage) : null);
            formData.append("max_per_user_usage",this.previewForm.max_per_user_usage ? parseInt(this.previewForm.max_per_user_usage) : null);

            this.couponIsSaving = true
            try{
                let res = await this.$axios.post('/updateCoupon',formData);
                if(res.data.status_code == "1072"){

                    this.$notify({
                        type: "success",
                        title: "Success",
                        message: res.data.message,
                    });

                    let couponIndex = this.couponsList.findIndex((coupon) => coupon.id = res.data.coupon.id);

                    this.couponsList[couponIndex] = res.data.coupon;
                    
                    this.$store.commit('coupons_module/update_coupons_list',res.data.coupon);

                    if(this.previewForm.id == res.data.coupon.id){

                        this.couponSaved = true;

                        this.closePreview();

                    }

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.id){

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.error.id[0],
                        });

                    }else if(error.response.data.error.includes('already')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error,
                        });

                    }else if(error.response.data.error.includes("doesn't support")){

                        let subscriptionPath = window.location.origin + "/subscription";

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error 
                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                        });

                    }else{

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: "error",
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.couponIsSaving = false;

            }

        },
        deleteCouponHandle(listedCoupon,index){
            
            if(!this.tourIsActive){

                MessageBox.confirm(
                    "Are you sure you want to delete the coupon?", 
                    "Warning", 
                    {
                        type: "warning",
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                    }
                ).then(async () => {

                    let loader = Loading.service({
                        text: "The coupon is being removed. Please wait!",
                        fullscreen: true,
                    });

                    this.deletingItems.push(listedCoupon.id);
                    this.deleteLoader= true

                    try{
                        let res = await this.$axios.post("/deleteCoupon", { id: listedCoupon.id });
                        if(res.data.status_code == "1073"){
                        
                            this.$notify({
                                type: "success",
                                title: "Success",
                                message: res.data.message,
                            });

                            // this.$store.commit('coupons_module/delete_coupons_list',index);

                            //removing deleted item from checked items list
                            this.checkedItems = this.checkedItems.filter((item) => {

                                if(item != listedCoupon.id){
                                    
                                    return item;
                                    
                                }

                            });

                            this.couponsLoading = true;

                            this.$store.dispatch('coupons_module/fetchStoreCouponsRequest',{size:11, page:1, loadData: 'new'});

                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.error.id){

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.error.id[0],
                                });

                            }else if(error.response.data.error.includes("doesn't support")){

                                let subscriptionPath = window.location.origin + "/subscription";

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    dangerouslyUseHTMLString: true,
                                    message: error.response.data.error 
                                    + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                });

                            }else{

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                        this.deletingItems = [];
                        this.deleteLoader = false;

                    }

                }).catch(() => {})
            
            }

        },
        //add/update & delete coupon end

        //bulk action start
        async bulkActionHandle(action){

            this.resetPreview();

            MessageBox.confirm(
                'Do you really want to make the changes?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async ()=>{

                this.deletingItems = _.cloneDeep(this.checkedItems);
                this.deleteLoader = true;

                let formData = new FormData();

                formData.append('resource_type', 'coupon');
                formData.append("resource_ids", JSON.stringify(this.checkedItems));
                formData.append('action', action);

                try{
                    let res = await this.$axios.post('/bulk-action',formData);
                    if(res.data.status_code == "2031"){

                        this.$notify({
                            type: "success",
                            title: "Success",
                            message: "Coupons have been successfully removed.",
                        });

                        // let payload = {
                        //     action: 'delete',
                        //     listIds: this.checkedItems
                        // }

                        // this.$store.commit('coupons_module/coupons_bulk_action',payload);

                        this.couponsLoading = true;

                        this.$store.dispatch('coupons_module/fetchStoreCouponsRequest',{size:11, page:1, loadData: 'new'});

                        this.checkedItems = [];

                    }
                }catch(error){

                    if(error.response){

                        if(error.response.data.error.includes("doesn't support")){

                            let subscriptionPath = window.location.origin + "/subscription";

                            this.$message({
                                type: 'error',
                                showClose: true,
                                dangerouslyUseHTMLString: true,
                                message: error.response.data.error 
                                + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                            });

                        }else if(error.response.data.message){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: "One of the coupons may have been deleted or isn't available.",
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    this.deletingItems = [];
                    this.deleteLoader = false;

                }

            }).catch(() => {})

        },
        //bulk action end

        //search coupon start
        searchCoupon(){

            this.resetPreview();

            this.couponsLoading = true;

            clearTimeout(this.timer);

            this.timer = setTimeout(() => {

                if(this.searchedItem.length > 0){

                    let payload = {
                        keyword: this.searchedItem,
                    }

                    this.$store.commit('coupons_module/update_searched_keyword',payload);
                    this.$store.dispatch('coupons_module/fetchStoreCouponsRequest',{size:11, page:1, loadData: 'new'});

                }else{

                    this.$store.commit('coupons_module/remove_searched_keyword');
                    this.$store.dispatch('coupons_module/fetchStoreCouponsRequest',{size:11, page:1, loadData: 'new'});

                }

            }, 500)

        },
        clearSearch(){

            this.searchedItem = ''

            this.searchCoupon();

        },
        //search coupon end

        //other methods start
        checkDate(){

            let valid_from = new Date(this.previewForm.valid_from)
            let valid_to = new Date(this.previewForm.valid_to)

            if(valid_to < valid_from){

                this.$notify({
                    type: 'warning',
                    title: 'Alert',
                    message: "Can't set valid from date before valid to date",
                });

                this.previewForm.valid_to = ''

                return

            }

        },
        isDeletingItem(id){

            let find = this.deletingItems.find(x=>x == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        checkedItemHandle(couponId){

            if(!this.tourIsActive && couponId != -1 && !this.previewForm.id){

                let index = this.checkedItems.findIndex(item => item == couponId);

                if(index != -1){

                    this.checkedItems.splice(index,1);

                }else{

                    this.checkedItems.push(couponId);

                }

            }

        },
        toggleIsCheckedAll(){

            if(!this.tourIsActive && !this.previewForm.id){

                if((this.checkedItems.length == this.couponsList.length)){

                    this.checkedItems = [];

                }else if(this.checkedItems.length < this.couponsList.length){

                    this.checkedItems = [];
                    
                    this.couponsList.forEach(brand => {

                        if(brand.id != -1){

                            this.checkedItems.push(brand.id);

                        }

                    });

                }

            }

        },
        clearSelection(){

          this.checkedItems = [];

        },
        expand(){

            this.$refs.viewInputForm.classList.toggle('fullWidthSlide');

        },
        couponsKeyEvent(e){

            if(e.key == 'Escape'){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.$refs.viewInputForm.classList.remove('fullWidthSlide');

                }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

                    this.closePreview();

                }

            }

        },
        //other methods end

        //tour methods start
        startCouponsTour(){

            this.$tours['couponsTour'].start();

            this.tourIsActive = true

            if(this.previewForm.id != ''){

                this.resetPreview();

            }

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 7){

                document.querySelector(".tableActionBtns").style.position = "static"

                document.querySelector(".productSlideBox").style.overflowY  = "hidden"

                this.createCoupon();

            }

            if(currentStep >= 15){

                document.querySelector(".tableActionBtns").style.position = "sticky"

            }

            if(currentStep + 1 == 18){

                this.expand();

            }

            if(currentStep + 1 == 19){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                } 

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 7){

                document.querySelector(".tableActionBtns").style.position = "sticky"

                document.querySelector(".productSlideBox").style.overflowY  = "auto"

                this.resetPreview();

            }

            if(currentStep <= 16){

                document.querySelector(".tableActionBtns").style.position = "static"

            }

            if(currentStep == 18){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep == 19){

                this.expand();

            }

        },
        handleTourStop(){

            document.querySelector(".tableActionBtns").style.position = "sticky"

            document.querySelector(".productSlideBox").style.overflowY  = "auto"

            this.resetPreview();

            this.tourIsActive = false

        },
        handleTourFinish(){

            document.querySelector(".tableActionBtns").style.position = "sticky"

            document.querySelector(".productSlideBox").style.overflowY  = "auto"

            this.resetPreview();

            this.tourIsActive = false

        },
        handleTourSkip(){

            document.querySelector(".tableActionBtns").style.position = "sticky"

            document.querySelector(".productSlideBox").style.overflowY  = "auto"

            this.resetPreview();

            this.tourIsActive = false

        },
        //tour methods end
    },
    mounted(){

        this.$refs.couponsTable.addEventListener('scroll',this.handleScrollEvent)

        window.addEventListener('keydown',this.couponsKeyEvent);

        window.addEventListener('beforeunload', (event) => {

            let index = null;

            this.couponsList.forEach((coupon) => {

                if(coupon.id == -1){

                    index = this.couponsList.indexOf(coupon);

                }

            });

            if(index != null){

                event.returnValue = "Are you sure you want to leave? entered information will be lost";

            }

        });

        if(this.$route.query.search){
      
            this.searchedItem = this.$route.query.search;

            if(this.$route.query.preview == 1){

                this.isMasterSearched = true;

            }

            this.$router.replace({'query': null});

            this.searchCoupon();

        }

    },
    beforeMount(){

        this.couponsLoading = true;
        try{

            if((!this.storeCoupons || this.storeCoupons.length == 0) && this.couponSearchKeyword.length == 0){
            
                this.$store.dispatch('coupons_module/fetchStoreCouponsRequest',{size:11, page:1, loadData: 'new'});
                
            }else{

                this.couponsList = _.cloneDeep(this.storeCoupons);
                this.couponsLoading = false;

            }

            if(this.couponSearchKeyword.length > 0){

                this.searchedItem = this.couponSearchKeyword;

            }

        }catch(error){

            this.couponsLoading = false;

            this.$message({
                type: "error",
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
            });

        }

    },
    beforeDestroy(){

        window.removeEventListener("keydown", this.couponsKeyEvent);
    
    },
    beforeRouteLeave(to, from, next){

        let index = null;

        this.couponsList.forEach((coupon) => {

            if(coupon.id == -1){

                index = this.couponsList.indexOf(coupon);

            }

        });

        if(window.innerWidth <= 1024){

            document.body.classList.remove('activeIpad');
            document.body.classList.add('hideSideBar');

        }

        if((this.previewForm.id || index != null) && to.fullPath != '/login'){

            MessageBox.confirm(
                'You may have unsaved changes, Proceeding will discard them',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Process',
                    cancelButtonText: 'Stay',
                }
            ).then(()=>{

                this.resetPreview();

                next();

            }).catch(()=>{});

        }else{

            next()

        }
        
    },
}
window.myFunction = function (event) {
    // reset all menu items
    document.querySelectorAll(".sectionTabsLink ul li a.active")
    .forEach(function (item) {
        item.classList.remove("active");
    });
    // mark as active selected menu item
    event.target.classList.add("active");
};
</script>

<style>

</style>